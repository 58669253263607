.ql-editor .ql-video {
    max-width: 700px;
    width: 100%;
    height: 400px;
    margin: 0 auto;
}

@media screen and (max-width:800px) { 
    .ql-editor .ql-video {
        width: 100%;
        height: 50vw;
    }
}